:root {
  --color-1: #2f44ff;
  --color-2: #7986ff;
  --color-3: #7986ff;
  --color-4: rgb(246, 237, 255);
  --color-white: #fff;
  --color-black: #111;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background-color: var(--color-white);
}
/* Nav CSS Starts */
nav {
  height: 75px;
}
.heading {
  background-color: var(--color-white);
}
nav,
.nav-1 {
  background-color: var(--color-white);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .logo {
  font-size: 30px;
  color: var(--color-black);
}
nav select {
  width: 150px;
}
.nav-1 select {
  width: 100%;
}
.btn-profile {
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: var(--color-1);
  color: var(--color-white);
  padding: 8px 12px;
}
.btn-profile i {
  color: var(--color-white);
  font-size: 18px;
  margin-right: 5px;
}
.login-btn {
  font-size: 15px;
}
.login-btn.hide-pc {
  display: none;
}
.navigation {
  padding: 20px 0;
}
.navigation ul {
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}
.navigation ul li {
  list-style: none;
  padding: 0 10px;
}
.navigation ul li a {
  padding: 20px 10px;
  font-size: 16px;
  color: var(--color-black);
  text-decoration: none;
}
.navigation ul li a:hover {
  color: var(--color-1);
}
.menu-btn i {
  color: var(--color-black);
  font-size: 25px;
  cursor: pointer;
  display: none;
}
#click {
  display: none;
}
.user-btn.hide-pc {
  display: none;
}
@media (max-width: 960px) {
  nav .logo {
    font-size: 25px;
  }
  .user-btn.hide-pc {
    display: block;
    margin-left: 5px;
  }
  .user-btn.hide-pc {
    margin-left: auto;
    margin-right: 10px;
  }
  .user-btn.hide-pc .btn-profile span {
    display: none;
  }
  .user-btn.hide-pc .btn-profile {
    border-radius: 50%;
  }
  .user-btn.hide-pc .btn-profile i {
    margin: 0;
    font-size: 16px;
  }
  .navigation {
    padding: 0;
    display: block;
  }
  #click:checked ~ .menu-btn i:before {
    content: "\F659";
  }
  .navigation ul {
    display: block;
    position: absolute;
    top: 75px;
    left: -100%;
    background: aliceblue;
    height: 100vh;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    z-index: 1 !important;
  }
  #click:checked ~ .navigation ul {
    left: 0%;
  }
  .navigation ul li {
    padding: 20px 0;
  }
  .navigation ul li:hover a {
    color: var(--color-1);
  }
  .menu-btn i {
    display: block;
  }
  .user-btn.hide-mobile {
    display: none;
  }
  .login-btn.hide-pc {
    display: block;
  }
  .login-btn.hide-mobile {
    display: none;
  }
}
@media (max-width: 375px) {
  .nav-1 select {
    font-size: 15px;
  }
}

.login-btn {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: var(--color-1);
  color: var(--color-white);
}
.login-btn:hover,
.login-btn:active {
  background-color: var(--color-1);
  color: var(--color-white);
}
.login-signup-header {
  height: 200px;
  display: flex;
}
.login-signup-header img {
  transform: scaleX(-1);
  width: 200px;
}
.login-signup-body {
  margin-top: 16px;
  padding: 10px 20px;
}
.login-signup-heading-text {
  text-align: center;
  font-size: 30px;
  color: var(--color-white);
  padding: 0 10px;
}
.login-signup-input label {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}
.login-signup-input input:hover {
  border-color: #e7d8d8;
  box-shadow: none;
}
.login-signup-input .enter-name {
  position: relative;
  margin-bottom: 20px;
}
.login-signup-input .enter-email {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.login-signup-input .enter-name input,
.login-signup-input .enter-email input {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 6px 0px;
  font-size: 20px;
  margin-left: 15px;
  width: 90%;
}
.login-signup-input .enter-name span,
.login-signup-input .enter-email span {
  position: absolute;
  margin-top: -50px;
  font-size: 20px;
  left: 0;
  padding: 10px 14px;
  transition: 0.3s;
  pointer-events: none;
}
.login-signup-input .enter-name input:valid ~ .login-inputs-spans,
.login-signup-input .enter-name input:focus ~ .login-inputs-spans,
.login-signup-input .enter-email input:valid ~ .login-inputs-spans,
.login-signup-input .enter-email input:focus ~ .login-inputs-spans {
  transform: translateY(-22px);
  font-size: 15px;
}
.otp-input-box {
  justify-content: center;
  margin: 0 auto;
}
.login-signup-input input[type="tel"] {
  height: 41px !important;
  font-weight: 600;
  padding: 5px;
  color: var(--color-1);
  border: 2px solid var(--color-1);
  border-radius: 3px;
}
.login-signup-input input[type="tel"]:focus {
  outline: none;
  border: 2px solid var(--color-1) !important;
}
.login-signup-input input[type="tel"]:not(:placeholder-shown) {
  border: 2px solid var(--color-1) !important;
}
.resend-otp-box {
  padding-top: 12px;
  color: var(--color-1);
}
.resend-otp-box > button {
  border: 0;
  background-color: transparent;
  color: var(--color-1);
}
.resend-otp-box > button:enabled {
  color: var(--color-1);
}
.terms-and-conditions-link input {
  padding: 0;
  border-radius: 0 !important;
}
.mobile-input {
  padding-left: 48px !important;
}
.mobile-input-flags {
  border: 2px solid var(--color-1) !important;
}
.mobile-input-ce {
  width: 100% !important;
  height: 50px !important;
}
/* Nav CSS Ends */
/* Slider CSS Starts */
.slider {
  position: sticky;
}
.img {
  height: 50vh;
  width: 100vw;
  z-index: -1;
  object-fit: cover;
  object-position: top right;
}
.select-city button,
.select-city button:hover,
.select-city button:active,
.select-city button:focus {
  background-color: var(--color-white);
  color: var(--color-2);
  border: 1px solid transparent;
}
.city {
  position: absolute;
  width: 100%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 5px;
}
.select-city {
  box-shadow: 0px 0px 10px rgb(88, 88, 88) !important;
}
.search-item {
  width: 40vw;
  border-left: 0;
  padding-left: 0;
}
.search-item:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
  border-left: 0;
}
.select-city button {
  padding: 13px 14px;
  font-size: 16px;
}
.location-btn .current-city i {
  color: var(--color-white);
  margin-right: 5px;
}
.location-btn > .current-city {
  padding: 7px 14px;
  font-size: 14px;
  color: var(--color-white);
  background-color: var(--color-1);
  border-radius: 100px;
}
.select-city button i {
  color: var(--color-1);
}
.select-city button span {
  background-color: var(--color-white);
  border-right: 0;
}
.search-box {
  width: 40vw;
}
.select-city button input {
  color: var(--color-1);
}
@media (max-width: 520px) {
  .select-city button {
    font-size: 12px;
  }
  .search-item {
    width: 20px;
  }
  .city {
    padding: 0 15px;
  }
}
@media (max-width: 380px) {
  .location-btn > .current-city {
    font-size: 13px;
  }
  .search-box {
    width: 30vw;
  }
}
.cities {
  height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}
.cities .row {
  padding: 0;
}
.cities .card:hover {
  border: 1px solid transparent;
  color: var(--color-1);
  background-color: var(--color-4);
}

/* Slider CSS Ends */
/* SellRepairTabs CSS Starts */
.line {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--color-2);
  margin: 5px 0 8px;
}
.icon-both {
  display: inline-flex;
  padding-top: 20px;
}
.icon-both .icon {
  background-color: var(--color-white);
  border-radius: 100%;
  font-size: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-both .icon i {
  color: var(--color-2);
}
.icon-both .icon i::before {
  transform: rotate(-20deg);
}
.icon-both span {
  font-size: 26px;
  padding-left: 15px;
  align-self: center;
}
.btn.dropdown-toggle.btn-light.bs-placeholder {
  background-color: var(--color-white) !important;
  color: rgb(138, 138, 138) !important;
  box-shadow: 0 0 15px rgb(224, 241, 255);
  padding: 8px 13px !important;
}
.dropdown-menu.inner.show,
.dropdown-item.selected.active {
  background-color: var(--color-white) !important;
}
.dropdown-item:active {
  color: var(--color-2) !important;
  background-color: var(--color-4) !important;
}
.dropdown-item {
  padding: 10px 12px !important;
  color: rgb(138, 138, 138) !important;
  border-bottom: 1px solid aliceblue;
}
.dropdown-menu.show {
  border-radius: 5px 5px 0 0 !important;
}
.bs-box.bs-searchbox.bg-light.border-bottom.border-primary {
  background-color: var(--color-white) !important;
  border-bottom: none !important;
  padding: 6px !important;
  margin: 2px !important;
}
/* .nav-tabs .nav-link.active{
  background-color: red;
  border-radius: 20px;
  transition: 0s;
} */
.desc-sell,
.desc-repair {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 10px 2px;
}
@media (max-width: 425px) {
  .desc-sell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .desc-both {
    padding-top: 10px;
  }

  .desc-sell {
    margin-right: 0px;
  }
  .desc-repair {
    margin-left: 0px;
  }
}
/* SellRepairTabs CSS Ends */

.btn.dropdown-toggle.btn-light.bs-placeholder,
.btn.dropdown-toggle.btn-light {
  margin: 0 !important;
}
.btn.dropdown-toggle.btn-light {
  background-color: var(--color-4) !important;
  color: var(--color-2) !important;
  /* border: 1px solid var(--color-1)!important; */
}
.dropdown-menu.show {
  padding-top: 0 !important;
}
.main {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: flex;
}
.main .form_wrapper {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main .form_wrapper .tile {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.main .form_wrapper .tile h3 {
  font-size: 22px;
  margin: 0px;
  transition: all 0.3s ease;
}
.main .form_wrapper .radio {
  display: none;
}
.main .form_wrapper .tab {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
  transition: color 0.3s ease;
  user-select: none;
  cursor: pointer;
}
.main .form_wrapper .tab.login_tab {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.main .form_wrapper .tab.signup_tab {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.main .form_wrapper .shape {
  background-color: var(--color-1);
  width: 50%;
  height: calc(40px - 1px);
  border-radius: 5px;
  position: absolute;
  top: 5px;
  left: 0px;
  opacity: 0.9;
  transition: all 0.4s ease;
}
.main .form_wrapper #signup:checked ~ .tab.login_tab,
.main .form_wrapper #login:checked ~ .tab.signup_tab {
  background-color: rgb(221, 221, 221);
}
.main .form_wrapper .shape:hover {
  background-color: var(--color-1);
}
.main .form_wrapper #login:checked ~ .shape {
  left: 0px;
}
.main .form_wrapper #login:checked ~ .login_tab {
  border-color: transparent;
  z-index: 1;
  color: white;
}
.main .form_wrapper #login:checked ~ .tile .signup {
  display: none;
}
.main .form_wrapper #login:checked ~ .form_wrap {
  transform: translateX(0);
}
.main .form_wrapper #login:checked ~ .form_wrap .signup_form {
  opacity: 0;
}
.main .form_wrapper #signup:checked ~ .shape {
  left: 50%;
}
.main .form_wrapper #signup:checked ~ .signup_tab {
  border-color: transparent;
  z-index: 1;
  color: white;
}
.main .form_wrapper #signup:checked ~ .tile .login {
  display: none;
}
.main .form_wrapper #signup:checked ~ .form_wrap {
  transform: translateX(-100%);
}
.main .form_wrapper #signup:checked ~ .form_wrap .login_form {
  opacity: 0;
}
.main .form_wrapper .form_wrap {
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  transition: transform 0.3s ease, opacity 0.2s ease;
}
.main .form_wrapper .form_wrap .form_fild {
  width: 100%;
  flex: 0 0 100%;
  transition: all 0.5s ease;
}

.main .form_wrapper .form_wrap .form_fild .btn {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
  margin-top: 24px;
  background-color: var(--color-1);
}
.main .form_wrapper .form_wrap .form_fild .btn:hover {
  background-color: var(--color-1);
}
.main .form_wrapper .form_wrap .form_fild .btn:active {
  transform: scale(0.95);
}

/*  */
.timeline .card {
  border: 0;
  margin-top: 100px;
}
.timeline .card .img {
  position: relative;
  background-color: var(--color-white);
  border-radius: 50%;
  padding: 25px;
  width: 100px;
  height: 100px;
  z-index: 1 !important;
  margin: auto;
  top: -50px;
  box-shadow: 0 0 10px rgb(222, 239, 253);
  transition: all 0.6s;
}
.timeline .card-body {
  margin-top: -45px;
  height: auto;
  text-align: justify;
  /* padding: 10px; */
}
.timeline .card:hover {
  background-color: var(--color-1);
  color: var(--color-white);
}
.timeline .card:hover .img {
  transform: scale(1.1);
}

/* Testimonial CSS Starts */
.testimonial-card-text {
  display: flex;
  padding-bottom: 5px;
}
.testimonial-card-text i {
  position: relative;
  font-size: 45px;
  opacity: 0.8;
  color: var(--color-1);
}
.testimonial-card-text span {
  margin-top: 20px;
}
.testimonial-card-footer {
  display: flex;
}
.testimonial-card-title {
  align-self: center;
}
.testimonial-profile-pic {
  height: 60px;
  width: 60px;
  margin-left: 10px;
  margin-right: 20px;
}
.testimonial-card-title {
  font-size: 16px;
  font-weight: 600;
}
.testimonial-designation {
  font-weight: normal;
  margin-top: -8px;
}
.testimonial-profile-pic img {
  clip-path: circle();
}
.testimonial-designation {
  margin-top: -5px;
}
.testimonial .owl-carousel {
  margin: 0 !important;
}
.testimonial .owl-stage-outer {
  padding: 0 !important;
}
.testimonial .owl-nav {
  position: absolute;
  font-size: 60px;
  margin-top: -320px !important;
  width: 100px;
  right: 0;
}
.testimonial .owl-nav button:hover {
  background-color: transparent !important;
}
.testimonial .owl-nav button span {
  color: var(--color-1);
}
@media (max-width: 400px) {
  .testimonial .owl-nav {
    margin-top: -340px !important;
  }
}
@media (max-width: 330px) {
  .testimonial .owl-nav {
    margin-top: -340px !important;
  }
}

/* Testimonial CSS Ends */

/* LoginSignUpForm CSS Starts */

/* LoginSignUpForm CSS Ends */

/*  */
.our-services .card {
  border-radius: 5px;
  transition: all 0.3s ease;
}
/* @media (max-width: 765px) { */
.our-services .card:nth-child(even) {
  background-color: rgb(221, 221, 221) !important;
}
.our-services .card:nth-child(odd) {
  background-color: #f2f2f2 !important;
}
.our-services .card:hover {
  color: var(--color-white);
  background-color: #2f44ff !important;
}
/* } */
/*  */
/* Footer CSS Starts */
footer a {
  text-decoration: none;
}
/* Footer CSS Ends */

/* Process Flow CSS Starts */
.process-menu.nav-pills .nav-link {
  color: #314047;
  margin-bottom: 15px;
  position: relative;
  padding: 10px 0;
  height: 105px;
}
.process-menu.nav-pills .nav-link .number {
  height: 35px;
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(116, 120, 141, 0.15);
  color: var(--color-2);
  border-radius: 40px;
}
.process-menu.nav-pills .nav-link.active {
  background-color: transparent !important;
  color: var(--color-1);
  height: 105px;
}
.process-menu.nav-pills .nav-link.active .number {
  color: #fff;
  background-color: var(--color-1);
}
.process-menu.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  border: 1px dashed var(--color-2);
  height: 65px;
  left: 17px;
  top: 54px;
}
@media (max-width: 768px) {
  .process-menu.nav-pills .nav-link::before {
    height: 45px;
  }
}
@media (max-width: 450px) {
  .process-menu.nav-pills .nav-link::before {
    height: 65px;
  }
}
.process-menu.nav-pills .nav-link:last-child::before {
  border: none;
}
/* Process Flow CSS Ends */

/* BOP form CSS Starts */

.bop-form .bop-form-input-box,
.ce-form .ce-form-input-box {
  position: relative;
  background-color: transparent;
  margin: 10px 0;
}
.bop-form .bop-form-input-box input,
.bop-form .bop-form-input-box textarea,
.ce-form .ce-form-input-box input,
.ce-form .ce-form-input-box textarea {
  padding: 10px 14px;
  color: gray;
  letter-spacing: 0.5px;
}
.bop-form .bop-form-input-box input:hover,
.bop-form .bop-form-input-box input:focus,
.bop-form .bop-form-input-box input:active,
.bop-form .bop-form-input-box textarea:hover,
.bop-form .bop-form-input-box textarea:focus,
.bop-form .bop-form-input-box textarea:active,
.ce-form .ce-form-input-box input:hover,
.ce-form .ce-form-input-box input:focus,
.ce-form .ce-form-input-box input:active,
.ce-form .ce-form-input-box textarea:hover,
.ce-form .ce-form-input-box textarea:focus,
.ce-form .ce-form-input-box textarea:active {
  box-shadow: none;
}
.bop-form .bop-form-input-box > span,
.ce-form .ce-form-input-box > span {
  position: absolute;
  top: 0;
  left: 8px;
  pointer-events: none;
  z-index: 1;
  transition: 0.5s;
  padding: 10px 24px;
  font-size: 16px;
  color: gray;
}
.bop-form .bop-form-input-box input:valid ~ span,
.bop-form .bop-form-input-box input:focus ~ span,
.bop-form .bop-form-input-box textarea:valid ~ span,
.bop-form .bop-form-input-box textarea:focus ~ span,
.ce-form .ce-form-input-box input:valid ~ span,
.ce-form .ce-form-input-box input:focus ~ span,
.ce-form .ce-form-input-box textarea:valid ~ span,
.ce-form .ce-form-input-box textarea:focus ~ span {
  color: blue;
  transform: translate(10px, -11px);
  background-color: var(--color-white);
  padding: 0 10px;
  font-size: 14px;
}
.bop-container,
.ce-container {
  padding: 0 50px;
  position: sticky;
  margin-top: 30px;
}
.bop-form-input-box select {
  padding: 10px 21px;
  font-size: 16px;
  color: gray;
  margin: 5px 0;
}
.bop-form-input-box select:active,
.bop-form-input-box select:focus {
  outline: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .bop-image,
  .ce-image {
    display: none;
  }
}
@media (max-width: 500px) {
  .bop-container,
  .ce-container {
    padding: 0 0 0 25px;
  }
}
/* BOP form CSS Ends */

/*  */
.brands .card,
.categories .card,
.series .card {
  border-radius: 5px;
  /* transition: all 0.6s ease-in-out; */
  background-color: #f2f2f2;
  width: 200px;
  margin: 10px 10px;
}
.brands .brands-icon,
.categories .categories-icon,
.series .series-icon {
  transition: all 0.3s ease-in-out;
}
.brands .card:hover .brands-icon,
.categories .card:hover .categories-icon,
.series .card:hover .series-icon {
  transform: scale(1.3);
}
.brands a,
.categories a,
.series a {
  text-decoration: none;
  color: var(--color-1);
}
.brands a .card-title,
.categories a .card-title,
.series a .card-title {
  color: var(--color-black);
}
/*  */

@media (max-width: 500px) {
  .brands .card,
  .categories .card,
  .series .card {
    margin: 8px 5px;
    width: 150px;
  }
}
/*  */
.series-search-box {
  padding: 30px 0;
}
.series-flex {
  display: flex !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
}
.series-search-box input {
  padding: 10px 14px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gainsboro;
}
@media (max-width: 500px) {
  .series-search-box input {
    width: 100%;
  }
  .series-search-box span {
    display: none;
  }
  .series-flex {
    justify-content: space-around !important;
  }
}
/*  */
.form-select.btn option {
  border: 5px solid red;
}

/*  */
.tac-pp-left-box {
}
.tac-pp-left-box.row {
  position: fixed;
  height: 10vh;
  width: 26%;
  padding-top: 20px;
}
.tac-pp-right-box {
  width: 80%;
  padding: 0 60px 0 10px;
  margin-left: 26%;
  text-align: justify;
}
.tac-pp-left-links-box {
  background-color: aliceblue;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  border-radius: 20px;
  padding: 8px 20px;
}
.tac-pp-left-box a:not(:nth-child(1)) {
  margin-top: 20px;
}
.tac-pp-left-box a {
  font-size: 14px;
  text-decoration: none;
  color: var(--color-black);
}
@media (max-width: 500px) {
  .tac-pp-left-box.row {
    position: absolute;
    width: 100%;
    padding-top: 0px;
    padding-left: 10px;
  }
  .tac-pp-right-box {
    margin-top: 30px;
    width: 100%;
    padding: 0 10px 0 10px;
    margin-left: 0;
  }
  .tac-pp-heading {
    display: none;
  }
}
/*
.tac-pp-left-links-box{
  background-color: #f2f2f2;
}
.tac-pp-left-box a{
  text-decoration: none;
  color: var(--color-black);
} */

/*  */

/*  */
.bottomToTopBtn {
  border: 0;
  background-color: var(--color-2);
  border-radius: 5px;
  color: var(--color-white);
  font-weight: bold;
}
.bottomToTopBtn:hover {
  background-color: var(--color-1);
  color: var(--color-black);
  box-shadow: 0 0 10px var(--color-3);
}
/*  */

/*  */
.chip,
.blog-desc-chip {
  font-size: 0.7rem;
  background: var(--color-1);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
  margin-top: -50px;
  margin-left: 12px;
}
.emptyList-wrap {
  display: flex;
  justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}
.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .searchBar-wrap {
    margin-top: 20px;
  }
}
.blogItem-wrap {
  display: flex;
  flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}

.blogItem-desc {
  position: relative;
  max-height: 60px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: "...";
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: flex-start;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
.searchBar-wrap {
  background-color: #f2f2f2;
  width: fit-content;
  /* margin: 2.5rem auto 4rem auto; */
  padding: 0.5rem;
  border-radius: 5px;
}

.searchBar-wrap form {
  display: flex;
  align-items: center;
}

.searchBar-wrap input {
  background-color: #f2f2f2;
  outline: none;
  border: none;
}

.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
}

.searchBar-wrap button {
  outline: none;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  background-color: var(--color-1);
  color: #fff;
}

.blog-wrap {
  max-width: 750px;
  margin: 0 auto;
}

.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  padding: 10px 0 0px 0;
  margin-bottom: 5px;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  margin: 10px 0;
}
.blog-subCategory > div {
  margin: 1rem 1rem 1rem 0;
}
.blog-desc {
  text-align: justify;
  color: gray;
  margin-top: 1.5rem;
}
.blog-desc-chip {
  margin-top: 0;
  margin-left: 0;
}

.recent-blogs-card:hover {
  background-color: aliceblue;
}
.catagories-link {
  border: 1px solid #ccc;
}
.catagories-link:hover {
  background-color: var(--color-1);
  border: none;
  color: var(--color-white);
}
.share-icons a {
  color: var(--color-1);
  opacity: 0.7;
}
.share-icons a:hover {
  opacity: 1;
}
/*  */

/*  */
.left-side {
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.right-side{
  border-radius: 0 5px 5px 0;
}
@media (max-width: 765px) {
  .left-side {
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }
  .right-side{
    border-radius: 0 0 5px 5px;
  }
}

/*  */



/*  */
.problems-card{
  width: 150px;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;
}
.problems-card img{
  object-fit: contain;
}
.problems-card .card-body{
  padding: 0
}
.problems-card .card-title{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 16px;
}
.problem-item{
  display: none;
}
.problem-item:checked + div .problems-card{
  border: 2px solid var(--color-1);
}
/*  */




/*  */
.configuration-radio-input:checked ~ .configuration-radio-input label{
  color: red
}
/*  */


/*  */
.bop-form-input-box input[type="datetime-local"]{
  color: gray;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/*  */

/*  */
@media (max-width: 768px) {
  .orders-image{
    width: 100% !important;
  }
}
/*  */